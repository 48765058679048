import algoliasearch, { SearchClient } from '@livv/custom-algoliasearch/lite';
import { FC, ReactNode, useEffect, useState } from 'react';
import { ConfigureProps, InstantSearch as InstantSearchWrapper } from 'react-instantsearch';
import Configure from './algolia/Configure';
import { INDEX_NAME_PERFORMED_AT_DESC } from '@utils/consts/search';
import useUser from '@utils/context/user';
import InsightsMiddleware from '@utils/InsightsMiddlewareAlgolia';
import getSearchApiKey from '@utils/search/getSearchApiKey';
import isAllowedToConsumePaidContent from '@utils/user';

interface InstantSearchProps {
    children: ReactNode;
    configureProps?: ConfigureProps;
    disableRouting?: boolean;
    indexName?: string;
    onLoadingChange?: (isLoading: boolean) => void;
    setDefaultSortByDateDesc?: boolean;
}

const InstantSearch: FC<InstantSearchProps> = ({
    children,
    configureProps,
    disableRouting = false,
    indexName: searchIndexName,
    onLoadingChange,
    setDefaultSortByDateDesc = false,
}) => {
    const { privileges } = useUser();
    const [indexConfig, setIndexConfig] = useState<{
        indexName: string;
        searchClient: SearchClient;
    }>();
    const [searchAnalyticsTags, setSearchAnalyticsTags] = useState<string[]>();

    const isPremiumUser = isAllowedToConsumePaidContent(privileges);

    useEffect(() => {
        onLoadingChange?.(true);
        getSearchApiKey(isPremiumUser).then(({ analyticsTags, appId, indexName, searchKey }) => {
            setIndexConfig({
                indexName,
                searchClient: algoliasearch(appId, searchKey),
            });
            setSearchAnalyticsTags(analyticsTags);
            onLoadingChange?.(false);
        });
    }, [isPremiumUser, onLoadingChange]);

    if (!indexConfig) {
        return null;
    }

    return (
        <InstantSearchWrapper
            future={{ preserveSharedStateOnUnmount: true }}
            indexName={
                searchIndexName ??
                (setDefaultSortByDateDesc ? INDEX_NAME_PERFORMED_AT_DESC : indexConfig.indexName)
            }
            routing={!disableRouting}
            searchClient={indexConfig.searchClient}
        >
            <Configure analyticsTags={searchAnalyticsTags} {...configureProps} />
            <InsightsMiddleware />
            {children}
        </InstantSearchWrapper>
    );
};

export default InstantSearch;
